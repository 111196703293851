<template>
  <div>
    <spinner-loader :loading="loading" />

    <div v-if="!loading">
      <b-card header="User Profile">
        <b-row>
          <b-col md="4">
            <b-row>
              <b-col>
                <b-avatar
                  :src="`${$mobileUsersImagesURL}${user.profile.avatar}`"
                  :text="avatarText(user.profile.first_name)"
                  size="104px"
                  rounded
                />
              </b-col>
              <b-col>
                <div>{{ `${user.profile.first_name} ${user.profile.last_name}` }}</div>
                <div>{{ user.email }}</div>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="4">
            <div>{{ user.phone }}</div>
            <div v-if="$moment().diff(user.birthday,'years')">
              {{ $moment().diff(user.birthday,'years') }} Years old
            </div>
            <div>{{ user.profile.gender }}</div>
            <div>Vaccinated: {{ user.profile.vaccinated ? 'Yes' : 'No' }}</div>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col md="6">
          <b-card header="User Preferences">
            <div class="followed-masjed p-1 mb-2">
              <h6 class="d-inline-block">
                Primary Masjed:
              </h6>
              <span> {{ user.entities.primary?user.entities.primary.name : '' }}</span>
            </div>
            <div
              v-if="user.entities.followed"
              class="followed-masjed p-1"
            >
              <h6>Followed Masjed:</h6>
              <b-list-group
                class="list"
              >
                <b-list-group-item
                  v-for="masjed in user.entities.followed"
                  :key="masjed.id"
                >
                  {{ masjed.name }}
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-card>
        </b-col>
        <b-col
          v-if="user.families"
          md="6"
        >
          <b-card header="Family Members">
            <div
              v-for="member in user.families"
              :key="member.id"
              class="shadow-card mb-1 p-2"
            >
              <b-row>
                <b-avatar
                  width="50%"
                  :text="avatarText('test')"
                  class="ml-1"
                />
                <b-col>
                  <h6>{{ member.first_name }}</h6>
                  <span>{{ member.relation }}</span>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="text-right"
                >
                  <span v-if="$moment().diff(member.birthday,'years')">
                    {{ $moment().diff(member.birthday,'years') }} Years |
                  </span>
                  <span>{{ member.gender }}</span>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div
        v-for="booking in user.bookings"
        :key="booking.id"
        class="shadow-card mb-2"
      >
        <b-card
          v-if="user.bookings.length && booking.session"
          header="User Bookings"
        >
          <h5>
            {{ activityName }} - {{ booking.session.session_provider }}
          </h5>
          <div>
            {{ booking.session.session_start }}
          </div>
          <div>{{ booking.session.session_address }}</div>
          <div
            v-if="booking.attendees.length!==0"
            class="mt-1"
          >
            {{ booking.attendees[0].attending_status }}
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'MobileUserView',
  components: { SpinnerLoader },
  data() {
    return {
      user: {},
      loading: true,
    }
  },
  computed: {
    activityName() {
      return this.booking?.session?.activity?.activity_name
    },
  },
  created() {
    this.getUserPreferences()
  },
  methods: {
    getUserPreferences() {
      this.$portalUsers.post('internalops/getUserMainInpos', {
        user_id: this.$router.currentRoute.params.id,
      }).then(res => {
        this.user = res.data.data
      }).finally(() => {
        this.loading = false
      })
    },
    avatarText,
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.followed-masjed {
 border: 1px solid rgb(41, 223, 255);
 border-radius: 3px;

 .list {
   overflow: auto;
   height: 8em;
 }
}

.shadow-card {
  box-shadow: 0 3px 10px 1px rgb(20 20 20 / 12%);
  border-radius: 5px;
}
</style>
